import MainCategory from '../components/Live/MainCategory';
import * as React from 'react';
import Header from "../components/Header";
import Sidebar from "../components/sidebar";

export interface ILiveCategoryScreenProps {
}

export default function LiveCategoryScreen (props: ILiveCategoryScreenProps) {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <MainCategory />
      </main>
    </>
  );
}
