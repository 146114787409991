import MainChannel from '../components/Live/MainChannel';
import * as React from 'react';
import Header from "../components/Header";
import Sidebar from "../components/sidebar";

export interface ILiveChannelScreenProps {
}

export default function LiveChannelScreen (props: ILiveChannelScreenProps) {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <MainChannel />
      </main>
    </>
  );
}
