import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { IMAGE_BUCKET_URL } from "../../api/baseConfig";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Table from "react-bootstrap/Table";
import { BASE_URL } from "../../api/baseConfig";
// ---------------------

// ---------------------
const PartnerEpisodes = ({ serieId }) => {
  const [episodes, setEpisodes] = useState([]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token
      fetch(`${BASE_URL}/api/partner/episodebypartnerid/${serieId}`, {
      headers: {
        'x-access-token': token,
      },
    })
      .then((res) => res.json())
        .then((json) => setEpisodes(json));
  }, [serieId]);


  return (
    <>
      <section className="content-main">
          {episodes.length === 0 ? (
          // <h2 className="fs-4 text-center">Yüklənir...</h2>
          <>
            <Skeleton />
            <Skeleton count={5} />
            <Skeleton count={5} />
            <Skeleton count={5} />
            <Skeleton count={5} />
          </>
        ) : (
          <div>
          <table className="table bordered">
            <thead>
              <tr className="text-center">
                <th>Şəkil</th>
                <th>Ad</th>
                <th>Baxış sayı</th>
                <th>Əlavə olunma tarixi</th>
              </tr>
            </thead>
            <tbody>
              {episodes?.sort((a, b) => new Date(b.add_date) - new Date(a.add_date)).map((item, index) => (
                <Tr item={item} key={index} />
              ))}
            </tbody>
          </table>
          {/* <div className="d-flex flex-column align-items-end">
            <h4>Ümumi:{episodes.payment_sum}</h4>
            <h4>Ödənildi:{episodes.partner_payed}</h4>
            <h4>Qaldı:{episodes.residual}</h4>
          </div> */}
          </div>
        )}
      </section>
    </>
  );
};

export default PartnerEpisodes;

const Tr = ({ item }) => {
  const dateObj =  new Date(item.add_date)
  const date = `${dateObj.getFullYear()}.${dateObj.getMonth()+1}.${dateObj.getDate()}`
  return (
    <tr className="text-center">
      <td>
        <img
          width={"80px"}
          height={"150px"}
          style={{ objectFit: "contain" }}
          src={IMAGE_BUCKET_URL + item.main_picture}
          alt=""
        />
      </td>
      <td>{item.name}</td>
      <td>{item.hit}</td>
      <td>{date}</td>
    </tr>
  );
};
