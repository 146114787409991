import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../api/baseConfig";
import Loading from "../LoadingError/Loading";
import { toast } from "react-toastify";
import Toast from "../LoadingError/Toast";

const ToastObjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 2000,
}
function parseM3UContent(content) {
    // Split content into lines and filter out empty lines
    const lines = content.split('\n').filter(line => line.trim());
    const channels = [];
    
    // Skip the first line (#EXTM3U)
    for (let i = 1; i < lines.length; i += 2) {
        // Check if we have both info line and URL line
        if (i + 1 < lines.length) {
            const infoLine = lines[i];
            const urlLine = lines[i + 1];
            
            // Extract category and name from info line
            // Example: #EXTINF:-1 group-title="İnformasiya",AzTV
            const regex = /group-title="([^"]+)",(.+)$/;
            const matches = infoLine.match(regex);
            
            if (matches) {
                channels.push({
                    name: matches[2].trim(),
                    category: matches[1],
                    url: urlLine.trim()
                });
            }
        }
    }
    
    return channels;
}

function MainLiveBulkLoad() {
    const [content, setContent] = useState('');
    const [channels, setChannels] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleContentChange = (e) => {
        setContent(e.target.value);
    };


    const parseHandler = () => {
        const channels = parseM3UContent(content);
        const categories = Array.from(new Set(channels.map(channel => channel.category)));
        const categoriesWithLang = categories.map(category => ({
            name: category,
            language: ['AZ', 'EN', 'RU', 'TR'].map(lang => ({
                lang_code: lang,
                name: category
            }))
        }));
        setCategories(categoriesWithLang);

        const channelInfo = {}
        channels.forEach(channel => {
            const category = categoriesWithLang.find(category => category.name === channel.category)
            if (channel.name in channelInfo && !channelInfo[channel.name].categories.includes(channel.category)) {
                channelInfo[channel.name].categories.push(category)
            } else {
                channelInfo[channel.name] = {
                    language: ['AZ', 'EN', 'RU', 'TR'].map(lang => ({
                        lang_code: lang,
                        name: channel.name
                    })),
                    url: channel.url,
                    categories: [category]
                }
            }
        })
        
        setChannels(Object.values(channelInfo));
    }

    const submitHandler = async () => {
        try {
            setLoading(true);
            const token = JSON.parse(localStorage.getItem('userInfo')).token;
            const categoriesResponse = await axios.post(`${BASE_URL}/api/livechannel/add-bulk-categories`, {
                categories: categories,
            },{headers: {
                "x-access-token": token,
            }})
            const newCategories = categoriesResponse.data.data
            setCategories(newCategories)
            toast.success('Categories added successfully', ToastObjects);

            const categorieIdInfo = {}
            newCategories.forEach(category => {
                categorieIdInfo[category.language[0].name] = category._id
            })

            const channelsWithCategoryIds = channels.map(channel => ({
                ...channel,
                categories: channel.categories.map(category => categorieIdInfo[category.name])
            }))

            const chunkSize = Math.ceil(channelsWithCategoryIds.length / 3);
            const chunks = [
                channelsWithCategoryIds.slice(0, chunkSize),
                channelsWithCategoryIds.slice(chunkSize, chunkSize * 2), 
                channelsWithCategoryIds.slice(chunkSize * 2)
            ];

            const responses = await Promise.all(chunks.map(chunk => 
                axios.post(`${BASE_URL}/api/livechannel/add-bulk-channels`, {
                    channels: chunk
                }, {headers: {
                    "x-access-token": token,
                }})
            ));

            const allChannels = responses.reduce((acc, response) => 
                [...acc, ...response.data.data], []);
                
            setChannels(allChannels);
            toast.success('Channels added successfully', ToastObjects);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error.response.data.message, ToastObjects);
        }
    }
    return (
        <div>
            <Toast />
            
            {loading && <Loading />}
            <section className="content-main">
                <div className="content-header">
                    <h2 className="content-title">Live Channels Bulk Load</h2>
                </div>
                <div className="card shadow-sm">
                    <div className="card-body">
                        <div id="header-section" className="row">
                            <div className="col-12 bg-dark bg-dark text-white text-center p-2 h3">
                                Total: {channels.length} channels, {categories.length} categories
                            </div>
                        </div>
                        <div id="load-section" className="row">
                            <div className="col-10">
                                <div className="form-group">
                                    <textarea 
                                        className="form-control" 
                                        rows="6" 
                                        placeholder="Enter your content here..."
                                        value={content}
                                        onChange={handleContentChange}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="d-flex flex-column gap-2">
                                    <button className="btn btn-warning w-100" onClick={parseHandler}>Parse</button>
                                    <button 
                                        className="btn btn-primary w-100" 
                                        onClick={submitHandler}
                                        disabled={channels.length === 0 || categories.length === 0}
                                    >Submit</button>
                                </div>
                            </div>
                        </div>
                        <div id="list-section" className="row mt-4">
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="card-header bg-primary text-white">
                                        <h5 className="mb-0">Categories</h5>
                                    </div>
                                    <div className="card-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                        {categories.map((category, index) => (
                                            <div className={`card mb-2 ${category._id ? 'bg-light border-success' : ''}`} key={index}>
                                                <div className="card-body">
                                                    <h6 className="card-title">{category.language[0].name}</h6>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="card-header bg-info text-white">
                                        <h5 className="mb-0">Channels</h5>
                                    </div>
                                    <div className="card-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                        {channels.map((channel, index) => (
                                            <div className={`card mb-3 shadow-sm ${channel._id ? 'bg-light border-success' : ''}`} key={index}>
                                                <div className="card-body p-3">
                                                    <div className="d-flex justify-content-between align-items-start">
                                                        <div>
                                                            <div className="d-flex align-items-center">
                                                                <h6 className="card-title mb-2 fw-bold me-2">{channel.language[0].name}</h6>
                                                                {channel.categories.map((category, index) => (
                                                                    <span key={index} className="badge bg-primary rounded-pill mb-1">{category.language[0].name}</span>
                                                                ))}
                                                            </div>
                                                            <div className="d-flex align-items-center text-muted">
                                                                <span className="small">{channel.url}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MainLiveBulkLoad;
