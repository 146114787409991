import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/sidebar";
import PartnerSeries from "../components/PartnerSeries/PartnerSeries";

const PartnerSeriesScreen = ({ match }) => {
  const partnerId = match.params.id;

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <PartnerSeries partnerId={partnerId} />
      </main>
    </>
  );
};

export default PartnerSeriesScreen;
