import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { IMAGE_BUCKET_URL } from "../../api/baseConfig";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Table from "react-bootstrap/Table";
import { BASE_URL } from "../../api/baseConfig";
import { Link } from "react-router-dom";
// ---------------------

// ---------------------
const PartnerSeries = ({ partnerId }) => {
  const [partner, setPartner] = useState({});

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token
    fetch(`${BASE_URL}/api/partner/seriebypartnerid/${partnerId}`, {
      headers: {
        'x-access-token': token,
      },
    })
      .then((res) => res.json())
      .then((json) => setPartner(json));
  }, [partnerId]);
  return (
    <>
   
      <section className="content-main">
      <div className="content-header">
                <h2 className="content-title">Seriallar</h2>
            </div>

        <Table className="table" striped bordered hover>
          <thead>
            <tr className="text-center">
              <th>AD</th>
              <th>SOYAD</th>
              <th>EMAIL</th>
              <th>TELEFON</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <td>
                <h5>{partner?.partner?.firstName}</h5>
              </td>
              <td>
                <h5>{partner?.partner?.lastName}</h5>
              </td>
              <td>
                <h5>{partner?.partner?.email}</h5>
              </td>
              <td>
        <h5>{partner?.partner?.phoneNumber}</h5>

                
              </td>
            </tr>
          </tbody>
        </Table>
      </section>
      <section className="content-main">
        {!partner.data ? (
          // <h2 className="fs-4 text-center">Yüklənir...</h2>
          <>
            <Skeleton />
            <Skeleton count={5} />
            <Skeleton count={5} />
            <Skeleton count={5} />
            <Skeleton count={5} />
          </>
        ) : (
          <div>
          <table className="table bordered">
            <thead>
              <tr className="text-center">
                <th>Şəkil</th>
                <th>Serial adı</th>
                <th>Qiymət</th>
                <th>Əlavə olunma tarixi</th>
                <th>Baxış sayı</th>
                <th>Ümumi baxış</th>
                <th>Sezon</th>
              </tr>
            </thead>
            <tbody>
              {partner.data?.map((item, index) => (
                <Tr item={item} key={index} />
              ))}
            </tbody>
          </table>
          {/* <div className="d-flex flex-column align-items-end">
            <h4>Ümumi:{partner.payment_sum}</h4>
            <h4>Ödənildi:{partner.partner_payed}</h4>
            <h4>Qaldı:{partner.residual}</h4>
          </div> */}
          </div>
        )}
      </section>
    </>
  );
};

export default PartnerSeries;

const Tr = ({ item }) => {
  // const dispatch = useDispatch();
  const dateObj =  new Date(item.add_date)
  const date = `${dateObj.getFullYear()}.${dateObj.getMonth()+1}.${dateObj.getDate()}`
  return (
    <tr className="text-center">
      <td>
        <img
          width={"80px"}
          height={"150px"}
          style={{ objectFit: "contain" }}
          src={IMAGE_BUCKET_URL + item.main_picture}
          alt=""
        />
      </td>
      <td>{item.name}</td>
      <td>{item.cash}</td>
      <td>{date}</td>
      <td>{item.hit}</td>
      <td>{item.hit}</td>
      <td>
        {item.seasons?.map((season, index) => (
          <Link to={`/partnerEpisodes/${season._id}`}>
          <button key={index} className="btn btn-success me-2">
            Sezon {season.name}
          </button>
          </Link>
        ))}
      </td>
    </tr>
  );
};
