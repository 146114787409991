import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../api/baseConfig";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Tab, Tabs } from "react-bootstrap";
import Toast from "../LoadingError/Toast";
import { listLanguage } from "../../Redux/Actions/LanguageActions";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const EditLiveCategory = ({categoryId}) => {
  const defaultVal = [
    { lang_code: "AZ", name: "" },
    { lang_code: "EN", name: "" },
    { lang_code: "RU", name: "" },
    { lang_code: "TR", name: "" },
  ];

  const [categoryLanguage, setCategoryLanguage] = useState(defaultVal);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const languageInfo = useSelector((state) => state.languageList);
  const { languages } = languageInfo ?? [];

  const dispatch = useDispatch();


  const handleChangeLanguage = (e, i) => {
    const { name, value } = e.target;
    const newState = [...categoryLanguage];
    newState[i] = {
      ...newState[i],
      [name]: value,
    };
    setCategoryLanguage(newState);
  };

  useEffect(() => {
    dispatch(listLanguage());
  }, [dispatch]);

  useEffect(() => {
    const fetchCategory = async () => {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const res = await axios.get(`${BASE_URL}/api/livechannel/categories/${categoryId}`, {
        headers: {
          'x-access-token': token,
        },
      });
      setCategoryLanguage(res.data.data.language);
      setLoading(false);
    }
    fetchCategory();
  }, [categoryId]);


  const updateCategory = async () => {
    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const res = await axios.put(`${BASE_URL}/api/livechannel/categories/${categoryId}`, {
        language: categoryLanguage,
      }, {
        headers: {
          'x-access-token': `${token}`,
        },
      });
      
      toast.success('Category updated successfully');
      setCategoryLanguage(res.data.data.language);
    } catch (error) {
      setError(error.response.data.message);
    }
  }

  const submitHandler = (e) => {
    e.preventDefault();
    updateCategory();
  };

  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={submitHandler}>
          <div className="content-header">
            <Link to="/live/category" className="btn btn-danger text-white">
              Go to categories
            </Link>
            <h2 className="content-title">Edit Category</h2>
            <div>
              <button type="submit" className="btn btn-primary">
                Publish now
              </button>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}
                  <Tabs>
                    {languages && categoryLanguage.length > 0 &&
                      languages.map((l, index) => (
                        <Tab
                          eventKey={l.name}
                          title={l.name}
                          key={`category-tab-${l.id}`}
                        >
                          <div className="mb-4">
                            <label
                              htmlFor={`category_title-${l.id}`}
                              className="form-label"
                            >
                              Category title {l.name}
                            </label>
                            <input
                              type="text"
                              placeholder="Type here"
                              className="form-control"
                              id={`category_title-${l.id}`}
                              name="name"
                              required
                              value={categoryLanguage[index].name}
                              onChange={(e) => handleChangeLanguage(e, index)}
                            />
                          </div>
                        </Tab>
                      ))}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default EditLiveCategory;
