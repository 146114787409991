import { Link } from "react-router-dom";
import { BASE_URL } from "../../api/baseConfig";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const ToastObjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 2000,
}

export default function MainCategory() {
    // Dummy data for categories
    const [categories, setCategories] = useState([]);

    const deleteCategory = async (id) => {
        try {
            const promptResult = window.confirm('Are you sure you want to delete this category?');
            if (!promptResult) {
                return;
            }
            const token = JSON.parse(localStorage.getItem('userInfo')).token;
            const res = await axios.delete(`${BASE_URL}/api/livechannel/categories/${id}`, {
                headers: {
                    'x-access-token': `${token}`,
                },
            });
            toast.success('Category deleted successfully', ToastObjects);
            loadCategories();
        } catch (error) {
            toast.error(error.response.data.message, ToastObjects);
        }
    }

    const loadCategories = async () => {
        try {
            const token = JSON.parse(localStorage.getItem('userInfo')).token;
            const res = await axios.get(`${BASE_URL}/api/livechannel/categories`, {
                headers: {
                    'x-access-token': `${token}`,
                },
            });
            setCategories(res.data.data);
        } catch (error) {
            toast.error(error.response.data.message, ToastObjects);
        }
    }

    useEffect(() => {
        loadCategories();
    }, [])

    return (
        <div>
            <section className="content-main">
                <div className="content-header">
                    <h2 className="content-title">Category List</h2>
                    <div>
                        <Link to="/live/category/add" className="btn btn-primary">
                            Create new
                        </Link>
                    </div>
                </div>
                <div className="card shadow-sm">
                    <div className="card-body">
                        <div className="row mb-4">
                            <div className="col-12 bg-dark text-white text-center p-2 h3">
                                Total {categories.length} Categories
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Category Name</th>
                                            <th scope="col" className="text-center">Edit</th>
                                            <th scope="col" className="text-center">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categories.map((category) => (
                                            <tr key={category.id}>
                                                <td>{category.language[0].name}</td>
                                                <td className="text-center">
                                                    <Link to={`/live/category/edit/${category._id}`}>
                                                        <button className="btn btn-warning">Edit</button>
                                                    </Link>
                                                </td>
                                                <td className="text-center">
                                                    <button className="btn btn-danger" onClick={() => deleteCategory(category._id)}>Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


