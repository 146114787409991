import { Link } from "react-router-dom";
import { BASE_URL } from "../../api/baseConfig";
import axios from "axios";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

export default function MainChannel() {
    const [channels, setChannels] = useState([]);
    const [q, setQ] = useState("");
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        axios.get(`${BASE_URL}/api/livechannel/channels`, {
            params: {
                q,
                page
            }
        })
        .then(res => {
            setChannels(res.data.data);
            setTotal(res.data.pagination.total);
            setTotalPages(res.data.pagination.pages);
        })
    }, [q, page]);

    const handlePageClick = (event) => {
        setPage(event.selected + 1);
    };

    return (
        <div>
            <section className="content-main">
                <div className="content-header">
                    <h2 className="content-title">Channel List</h2>
                    <div>
                        <Link to="/live/channels/add" className="btn btn-primary">
                            Create new
                        </Link>
                    </div>
                </div>
                <div className="card shadow-sm">
                    <div className="card-body">
                        <div className="row mb-4">
                            <div className="col-12 bg-dark text-white text-center p-2 h3">
                                Total {total} Channels
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-6">
                                <input
                                    type="search"
                                    placeholder="Search channels..."
                                    className="form-control p-2"
                                    value={q}
                                    onChange={(e) => setQ(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: '60%' }}>Channel Name</th>
                                            <th scope="col" className="text-center" style={{ width: '20%' }}>Edit</th>
                                            <th scope="col" className="text-center" style={{ width: '20%' }}>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {channels.map((channel) => (
                                            <tr key={channel.id}>
                                                <td style={{ width: '60%' }}>{channel.language[0].name}</td>
                                                <td className="text-center" style={{ width: '20%' }}>
                                                    <Link to={`/live/channels/edit/${channel._id}`}>
                                                        <button className="btn btn-warning">Edit</button>
                                                    </Link>
                                                </td>
                                                <td className="text-center" style={{ width: '20%' }}>
                                                    <button className="btn btn-danger">Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="next>"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                pageCount={totalPages}
                                previousLabel="< previous"
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                                renderOnZeroPageCount={1}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


