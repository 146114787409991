import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listLanguage } from "../../Redux/Actions/LanguageActions";
import { BASE_URL, IMAGE_BUCKET_URL } from "../../api/baseConfig";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Select from "react-select";

import Toast from "../LoadingError/Toast";
import { Tab, Tabs } from "react-bootstrap";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const defaultVal = [
  { lang_code: "AZ", name: "" },
  { lang_code: "EN", name: "" },
  { lang_code: "RU", name: "" },
  { lang_code: "TR", name: "" },
];  

const EditLiveChannel = () => {

  const dispatch = useDispatch();
  const { languages } = useSelector((state) => state.languageList);
  const { id } = useParams();

  const [channelLanguage, setChannelLanguage] = useState(defaultVal);
  const [url, setUrl] = useState("");
  const [image, setImage] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryInput, setCategoryInput] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);

  const [previewImage, setPreviewImage] = useState("");
  const [mainImg, setMainImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleImageUpload = (file) => {
    setMainImg(file);
    previewFile(file, setPreviewImage);
  };

  const previewFile = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      state(reader.result);
    };
  };
  
  const handleChangeLanguage = (e, i) => {
    const { name, value } = e.target;
    const newState = [...channelLanguage];
    newState[i] = {
      ...newState[i],
      [name]: value,
    };
    setChannelLanguage(newState);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem('userInfo')).token;
    const formData = new FormData();
    const channelInfo = {
      language: channelLanguage,
      url: url,
      categories: categories.map(c => c._id),
      main_picture: image ?? "",
    }
    formData.append("channel", JSON.stringify(channelInfo));
    if (mainImg) {
      formData.append("image", mainImg);
    }
    
    axios.put(`${BASE_URL}/api/livechannel/channels/${id}`, formData, {
      headers: {
        'x-access-token': `${token}`,
      },
    }).then((res) => {
      setLoading(false);
      toast.success('Live Channel updated successfully', ToastObjects);
    }).catch((err) => {
      setLoading(false);
      setError(err.response.data.message);
    });
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (categoryInput) {
        setCategories([...categories, { label: categoryInput, value: categoryInput }]);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [categoryInput, categories]);

  useEffect(() => {
    dispatch(listLanguage());
  }, [dispatch]);

  useEffect(() => {
    const fetchCategories = async () => {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const res = await axios.get(`${BASE_URL}/api/livechannel/categories`, {
        headers: {
          'x-access-token': `${token}`,
        },
      });
      setCategoryOptions(res.data.data);
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchChannel = async () => {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const res = await axios.get(`${BASE_URL}/api/livechannel/channels/${id}`, {
        headers: {
          'x-access-token': `${token}`,
        },
      });
      setChannelLanguage(res.data.data.language);
      setUrl(res.data.data.url);
      setImage(res.data.data.image);
      setPreviewImage(res.data.data.image);
      const currentCategories = Array.isArray(res.data.data.categories) ? 
        res.data.data.categories : 
        [res.data.data.categories];
      setCategories(currentCategories.filter(cat => cat && cat._id));
    };
    fetchChannel();
  }, [id]);

  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={submitHandler}>
          <div className="content-header">
            <Link
              to={`/live/channels`}
              className="btn btn-danger text-white"
            >
              Go to Live Channels
            </Link>
            <h2 className="content-title">Edit Live Channel</h2>
            <div>
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            </div>
          </div>
          {error && <Message variant="alert-danger">{error}</Message>}
          {loading && <Loading />}
          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  
                <Tabs>
                    {languages && channelLanguage.length > 0 &&
                      languages.map((l, index) => (
                        <Tab
                          eventKey={l.name}
                          title={l.name}
                          key={`category-tab-${l.id}`}
                        >
                          <div className="mb-4">
                            <label
                              htmlFor={`category_title-${l.id}`}
                              className="form-label"
                            >
                              Category title {l.name}
                            </label>
                            <input
                              type="text"
                              placeholder="Type here"
                              className="form-control"
                              id={`category_title-${l.id}`}
                              name="name"
                              required
                              value={channelLanguage[index].name}
                              onChange={(e) => handleChangeLanguage(e, index)}
                            />
                          </div>
                        </Tab>
                      ))}
                  </Tabs>
                  <div className="mb-4">
                    <label className="form-label">
                      URL
                    </label>
                    <input type="text" className="form-control" placeholder="http://www.example.m3u8" required value={url} onChange={(e) => setUrl(e.target.value)} />
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Images</label>
                    <input
                      className="form-control mt-3"
                      type="file"
                      onChange={(e) => handleImageUpload(e.target.files[0])}
                    />
                    {previewImage && (
                      <img width={150} src={previewImage.startsWith('data:') ? previewImage : `${IMAGE_BUCKET_URL}${previewImage}`} alt="" />
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Category</label>
                    <Select
                      onChange={(option) => setCategories(option || [])}
                      options={categoryOptions}
                      isMulti={true}
                      getOptionLabel={(opt) => opt?.language?.[0]?.name || ''}
                      getOptionValue={(opt) => opt?._id || ''}
                      onInputChange={(e) => setCategoryInput(e)}
                      value={categories}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default EditLiveChannel;
